import Emitter from "services/Emitter";
import moment from "moment";
import { appInsights } from "../AppInsights";
import { API_VERSION, API_VERSION_V2, FORTIFIED_DATA_API_URL } from "../config";
import { setIsDownloading } from "../redux/api/api.actions";
import { store } from "../redux/store";
import { ApiCallParams } from "./ApiCallParams";
import { AUTH_API_PATH } from "Consts";

export default class API {
  static getAuthHeaders() {
    return {};
  }

  /**
   *
   * @param {ApiCallParams} apiCallParams
   */
  // eslint-disable-next-line class-methods-use-this
  async call(apiCallParams: ApiCallParams) {
    if (localStorage.getItem("maintenanceMode") === "true") {
      window.location.href = "/";
    }

    if (apiCallParams.responseType === "blob") {
      store.dispatch(setIsDownloading(true));
    }

    // const accessToken: any = await getAccessToken();
    const abortController = new AbortController();

    const headers = apiCallParams.headers;

    try {
      const normalizedParams: RequestInit = {
        method: apiCallParams.method,
        headers: headers,
        signal: abortController.signal,
        credentials: "include",
      };

      if (apiCallParams.body) {
        normalizedParams.body = apiCallParams.body;
      }

      const isV2 = apiCallParams.action.includes("v2/");
      const path = isV2 ? apiCallParams.action.split("v2/")[1] : apiCallParams.action;
      const query =
        Object.keys(apiCallParams.query).length > 0
          ? `?${Object.keys(apiCallParams.query)
              .map((key) => `${key}=${encodeURIComponent(apiCallParams.query[key])}`)
              .join("&")}`
          : "";

      const url = `${FORTIFIED_DATA_API_URL}/${isV2 ? API_VERSION_V2 : API_VERSION}/${path}${query}`;

      try {
        Emitter.on("pageUnload", () => {
          abortController.abort();
        });
        Emitter.on("cancelRequest", () => {
          abortController.abort();
        });
        let response = await fetch(url, normalizedParams);
        Emitter.off("pageUnload", () => {});
        //todo: send to app insights
        if (response.status === 401 && path !== AUTH_API_PATH) {
          // 401 unauthorized

          //retry one more time
          await new Promise((f) => setTimeout(f, 2000));
          response = await fetch(url, normalizedParams);

          if (response.status === 401) {
            appInsights?.trackTrace({
              message: "pre logout response.status = 401",

              //severity: 1,
              properties: {
                origin: "Api.js",
                request: url,
              },
            });

            window.location.href = `//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ""
            }/logout`;

            return;
          }
        }

        if (response.status === 403) {
          Emitter.emit("displayApiErrors", [{ path: "general", message: "Access not authorized" }]);
          //debugger;
          appInsights?.trackTrace({
            message: "unauthorized access registered",
            // severity: 1,
            properties: {
              origin: "Api.js",
              request: url,
            },
          });

          return {
            errors: [{ path: "general", message: "Not authorized Action" }],
          };
        }

        if (response.status === 204 || response.status === 202) return null; // no content

        if (!apiCallParams.responseType || apiCallParams.responseType === "json") {
          const json = await response.json();

          if (response.status >= 400 && !json.errors) {
            json.errors = [{ path: "general", message: "Unknown Server Error." }];

            appInsights?.trackTrace({
              message: "unknown api call error",
              properties: {
                origin: "Api.js",
                request: url,
                requestBody: normalizedParams,
              },
            });
            if (json.Errors) {
              Emitter.emit("displayApiErrors", json.Errors);
            } else {
              Emitter.emit("displayFailure", "Unknown error has occurred.");
            }
          } else if (json.errors) {
            Emitter.emit("displayApiErrors", json.errors);
          }
          if (typeof json === "object" && !Array.isArray(json) && json !== null) {
            json.isAborted = false;
          }

          return json;
        }
        if (apiCallParams.responseType === "blob") {
          const blob = await response.blob();

          if (response.status >= 400) {
            Emitter.emit("displayFailure", "Download error occurred.");
          } else {
            let fileName = "";
            response.headers.forEach((val, header) => {
              if (header == "content-disposition") {
                if (val && val.indexOf("attachment") !== -1) {
                  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  const matches = filenameRegex.exec(val);

                  if (matches != null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, "");
                  }
                }
              }
              if (header === "content-type" && val === "application/octet-stream") {
                fileName = "WisdomInstaller";
              }

              if (response?.url?.includes("assess_hosts_for_cloud")) {
                fileName = `Migration-Recommendation-${moment(new Date()).format("YYYYMMDD")}`;
              }

              if (response?.url?.includes("assessment")) {
                fileName = `Assessment-Overview-${moment(new Date()).format("YYYYMMDD")}`;
              }

              if (response?.url?.includes("clients_logins")) {
                fileName = `Accounts-Export-Logins-${moment(new Date()).format("YYYYMMDD")}`;
              }
            });
            if (fileName) {
              const link = document.createElement("a");
              const url = window.URL.createObjectURL(blob);
              link.download = fileName === "WisdomInstaller" ? `${fileName}.msi` : fileName;
              link.href = url;
              link.click();
              window.URL.revokeObjectURL(url);
            } else {
              const file = window.URL.createObjectURL(blob);
              window.open(file);
            }
          }
          return null;
        } else {
          throw new Error(`unsupported response type ${apiCallParams.responseType}`);
        }
      } catch (error: any) {
        if (error.name === "AbortError") return { data: [], isAborted: true };
        const message =
          "An internal error occurred while calling the server. Please try to reload the page.";

        const json = {
          errors: [
            {
              path: "general",
              message,
            },
          ],
        };

        Emitter.emit("displayApiErrors", json.errors);

        appInsights?.trackTrace({
          message: "invalid api call endpoint error",
          properties: {
            origin: "Api.js",
            request: url,
            requestBody: normalizedParams,
            error,
          },
        });
        return json;
      }
    } catch (error: any) {
      console.log(
        "There has been a problem with the fetch operation: ",
        apiCallParams,
        error.message
      );
      return null;
    } finally {
      if (apiCallParams.responseType === "blob") {
        store.dispatch(setIsDownloading(false));
      }
    }
  }
}
