import * as Excel from "exceljs";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import Emitter from "services/Emitter";
import moment from "moment";
import {
  renderAvailability,
  renderPercentage,
  renderRoundNumeric,
  renderUnixTS,
} from "utils/tableUtils";
import { ExcelExportTableOptions, FDTableColumn } from "types/app";
import {
  TABLE_FORMAT_FULL,
  TABLE_FORMAT_MINUTES,
  convertUIDatesToMomentUserTzDates,
  formatDate,
  formatDateNoTimezone,
  formatDateUnixMS,
} from "utils/utils";

const renderExcelUnixTSMinutes = (unix, currentUser) =>
  formatDateUnixMS(unix, currentUser, TABLE_FORMAT_MINUTES);

const renderExcelDate = (stringDate, currentUser) =>
  formatDate(stringDate, currentUser, TABLE_FORMAT_FULL);

const renderExcelDateTimeSpan = (stringDate, currentUser) =>
  formatDate(stringDate, currentUser, "HH:mm:ss");

const renderExcelUnixTS = (unix, currentUser) => formatDateUnixMS(unix, currentUser);
const renderExcelDateServerTZ = (stringDate) => formatDateNoTimezone(stringDate);

const ExcelCellLimit = 32760; // actually 32767 but we're adding ellipsis "..."

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const nameConverter = (name: string | any, symbol: string) => {
  const nameToConvert: string | any = name;
  if (typeof nameToConvert !== "string") return 0;
  if (typeof name === "string" && !nameToConvert?.includes(symbol))
    return capitalizeFirstLetter(nameToConvert);
  if (typeof name === "string" && nameToConvert?.includes(symbol)) {
    return nameToConvert
      ?.split(symbol)
      ?.map((i) => capitalizeFirstLetter(i).replace(symbol, "-"))
      ?.join("-");
  }
};

const lowerizeFirstLetter = (string: string) => string.charAt(0).toLowerCase() + string.slice(1);

export const camelCaseConverter = (name: any) => {
  const nameToConvert = name;
  if (typeof nameToConvert !== "string") return 0;

  return nameToConvert
    ?.split(" ")
    ?.map((i, index) => (index === 0 ? lowerizeFirstLetter(i) : capitalizeFirstLetter(i)))
    .join("");
};

export const generateExcelFileName = (title) => {
  const date = moment(new Date()).format("YYYYMMDD HH:MM");
  const urlArr = location.pathname.split("/");
  const screenUrl = urlArr[urlArr.length - 1];
  const screenName = nameConverter(screenUrl, "_");
  const name = nameConverter(title, " ");

  return typeof name === "string"
    ? `${screenName}-${name}-${date}.xlsx`
    : `${screenName}-${date}.xlsx`;
};

export const exportFDDataTable = (
  exportOptions: ExcelExportTableOptions[],
  currentUser: any,
  fileName: string
) => {
  const workbook: Workbook = new Excel.Workbook();

  try {
    exportOptions.forEach((options) => {
      const { columns, data } = options;
      const worksheet = workbook.addWorksheet(options.sheetName);

      //https://github.com/exceljs/exceljs#columns
      worksheet.columns = getExcelColumns(columns);
      worksheet.getRow(1).font = { bold: true };

      formatExcelData(data, columns, worksheet, currentUser);

      adjustColumnWidths(worksheet).then(() => {
        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          saveAs(blob, fileName);
        });
      });
    });
  } catch (error) {
    Emitter.emit("displayFailure", "An error occurred while exporting the table.");
    return;
  }
};

function formatExcelData(
  data: any[],
  columns: Array<FDTableColumn>,
  worksheet: Excel.Worksheet,
  currentUser: any
) {
  data.forEach((dataObj: any) => {
    const exportedRow = {};

    columns.forEach((column) => {
      const field: string = column.exportField ?? (column.id || column.field); // should be fields but if "id" used to be id so adding anyway
      let excelVal = dataObj[field];

      if (column.renderExcel) {
        excelVal = column.renderExcel(excelVal, dataObj);
      } else if (column.useRenderForExcel && column.render) {
        excelVal = column.render(excelVal, dataObj);
      } else if (column.render == renderRoundNumeric) {
        excelVal = Number.isNaN(+excelVal) ? null : +excelVal;
      } else if (column.render == renderAvailability || column.render == renderPercentage) {
        excelVal = Number.isNaN(+excelVal) ? null : +excelVal / 100;
      } else if (column.dataType) {
        if (column.dataType === "boolean") {
          excelVal = excelVal === true || excelVal === "true" ? "Yes" : "No";
        } else if (
          column.render == renderRoundNumeric ||
          column.dataType === "roundNumber" ||
          column.dataType === "number" ||
          column.dataType === "positiveNumber"
        ) {
          excelVal = Number.isNaN(+excelVal) ? null : +excelVal;
        } else if (
          column.render == renderAvailability ||
          column.render == renderPercentage ||
          column.dataType === "availability" ||
          column.dataType === "percents"
        ) {
          excelVal = Number.isNaN(+excelVal) ? null : +excelVal / 100;
        } else if (column.dataType === "date") {
          if (column.format) {
            if (column.render == renderUnixTS || column.format === "unix") {
              excelVal = renderExcelUnixTS(excelVal, currentUser);
            } else if (column.format === "unix_minutes") {
              excelVal = renderExcelUnixTSMinutes(excelVal, currentUser);
            } else if (column.format === "original_tz") {
              excelVal = renderExcelDateServerTZ(excelVal);
            } else if (column.format === "time_span") {
              excelVal = renderExcelDateTimeSpan(excelVal, currentUser);
            } else {
              excelVal = renderExcelDate(excelVal, currentUser);
            }
          } else {
            excelVal = renderExcelDate(excelVal, currentUser);
          }
        } else {
          if (typeof excelVal === "string" && `${excelVal}`.length > ExcelCellLimit) {
            excelVal = `${excelVal.substring(0, ExcelCellLimit)}...`;
          }
        }
      }

      if (Array.isArray(excelVal)) {
        excelVal = excelVal.join("\n");
      }
      exportedRow[field] = excelVal ?? null;
    });

    worksheet.addRow(exportedRow);
  });
}

function getExcelColumns(columns: any) {
  const excelColumns = columns.map((column: any) => {
    const excelColumn: any = {
      header: column.excelName || column.name || column.display,
      key: column.exportField ?? (column.field || column.id),
      style: {},
      //style: { font: { bold: true } }
    };

    /**numFmt
     * 0 - Whole number
      1 - Whole number with commas
      2 - Number with two decimal places
      3 - Number with two decimal places and commas
      4 - Number with two decimal places, minus sign for negative values
      5 - Number with two decimal places, minus sign for negative values in parentheses
      6 - Whole number, minus sign for negative values
      7 - Whole number, minus sign for negative values in parentheses
      8 - Number in scientific notation with two decimal places
      9 - Number in scientific notation with one decimal place

     */
    if (column.render == renderAvailability) {
      excelColumn.style.numFmt = "#,##0.000%";
    }
    if (column.render == renderPercentage) {
      excelColumn.style.numFmt = "#,##0.00%";
    } else if (column.dataType) {
      if (column.dataType === "number" || column.dataType === "positiveNumber") {
        excelColumn.style.numFmt = "#,##0.00";
      } else if (column.dataType === "roundNumber") {
        excelColumn.style.numFmt = "#,##0";
      } else if (column.dataType === "percents" || column.render == renderPercentage) {
        debugger;
        excelColumn.style.numFmt = "#,##0.000%";
      } else if (column.dataType === "availability") {
        excelColumn.style.numFmt = "#,##0.000%";
      } else {
        // Handle the default case here if needed
      }
    }
    return excelColumn;
  });

  return excelColumns;
}

async function adjustColumnWidths(worksheet) {
  // Loop through all columns in the worksheet
  worksheet.columns.forEach((column) => {
    let maxLength = 0;

    // Loop through all rows in the current column
    column.eachCell({ includeEmpty: true }, (cell, rowNumber) => {
      // Get the cell's content length
      const cellLength = (cell.value && cell.value.toString().length) || 0;

      // Update the max length if the current cell's content is longer
      if (cellLength > maxLength) {
        maxLength = cellLength;
      }
    });

    // Add padding to the max length
    maxLength += 2;

    // Set the adjusted width to the column
    column.width = maxLength;
  });
}
