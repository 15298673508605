import { store } from "../redux/store";
import moment from "moment";
import {
  formatBytes,
  formatDateUnixMS,
  formatGB,
  formatMB,
  formatNumber,
  formatPercentage,
  formatTime,
  isNullOrUndefined,
  TABLE_FORMAT_FULL,
} from "utils/utils";

export const renderMB = (val) => {
  if (isNullOrUndefined(val)) return null;
  return `${formatMB(val)}`;
};

export const renderPercentage = (val: any) => {
  const p = formatPercentage(val, 0, null);
  return Number.isNaN(p) ? null : p;
};

export const renderGB = (val) => {
  if (isNullOrUndefined(val)) return null;
  const g = formatGB(val);
  return `${Number.isNaN(g) ? null : g}`;
};
export const renderNumeric = (val: string | number) => {
  return formatNumber(val); // todo: support col.decimalPoints
};

export const renderAvailability = (val: any) => {
  return `${formatPercentage(val, 3, null)}`;
};
export const renderRoundNumeric = (val) => {
  return formatNumber(val, 0); // todo: support col.decimalPoints
};

export const renderBytes = (val) => {
  if (isNullOrUndefined(val)) return null;
  return `${formatBytes(val)}`;
};

export const renderDate = (stringDate) => {
  if (isNullOrUndefined(stringDate)) {
    return null;
  }

  return moment(stringDate).format(TABLE_FORMAT_FULL);
};

export const renderTime = (ms) => formatTime(ms);

export const renderUnixTS = (unix) => {
  const state: any = store.getState();
  return formatDateUnixMS(unix, state.auth.currentUser);
};

export const renderBoolText = (val: any) => {
  if (val) {
    return "Yes";
  } else {
    return "No";
  }
};

export const filterDataBySearchText = (
  data: any[],
  searchTerm: string,
  searchTextInFields?: string[]
) => {
  return data.filter((row) => {
    if (!searchTerm || searchTerm?.length === 0) {
      return true;
    }

    const lower = searchTerm.toLowerCase();
    if (searchTextInFields) {
      return searchTextInFields.some((field) => {
        const v = row[field];
        return `${v}`.toLowerCase().includes(lower); //contains
      });
    } else {
      return Object.values(row).some((v) => `${v}`.toLowerCase().includes(lower)); //contains
    }
  });
};
